<template>
	<div class="sellerTools white">
		<div class="toolsHead">
			<span class="toolsHeadName">商家工具箱</span>
			<span class="toolsHeadMore hoverHand" @click="goSellerTools('http://tool.aiodiy.cn/')">更多</span>
		</div>
		<div class="toolsType">
			<div
				class="toolsTypeItem"
				v-for="(item,index) in toolsTypeList"
				:key="index"
				@click="goSellerTools(item.path)">
				<i class="iconfont" :class="item.icon"></i>
				<span>{{item.name}}</span>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				toolsTypeList: [
					{
						name: '排名管理',
						icon: 'icon-paiming',
						// path: '/pageIndex/sellerTools/rank',
						path: 'http://tool.aiodiy.cn/operatingTools/rankingQuery',

					}, {
						name: '黑号查询',
						icon: 'icon-chakan',
						// path: '/pageIndex/sellerTools/blackQuery',
						path: 'http://tool.aiodiy.cn/operatingTools/wagnwangInfo',

					}, {
						name: '旺旺自动打标',
						icon: 'icon-M_dingding2',
						path: 'http://tool.aiodiy.cn/operatingTools/TaobaoMarking',


						// path: '/pageIndex/sellerTools/mark',
					}, {
						name: '关键词卡首屏',
						icon: 'icon-anquanzhongxin',
						// path: '/pageIndex/sellerTools/firstPage',
						path: 'http://tool.aiodiy.cn/operatingTools/screenGetsStuck',

					},
					{
						name: '礼品代发',
						icon: 'icon-xihuan',
						// path: '/pageIndex/sellerTools/guessLike',
						path: 'http://tool.aiodiy.cn/giftCenter/buyGifts',

					},
					// {
					// 	name: '指数转换',
					// 	icon: 'icon-wulumuqishigongandashujuguanlipingtai-ico-',
					// 	// path: '/pageIndex/sellerTools/transform',
					// 	path: 'http://tool.aiodiy.cn/operatingTools/TaobaoMarking',
					//
					// },
					// {
					// 	name: '骗子库',
					// 	icon: 'icon-xiaoemo',
					// 	// path: '/pageIndex/sellerTools/liar',
					// 	path: 'http://tool.aiodiy.cn/operatingTools/TaobaoMarking',
					//
					// },
					// {
					// 	name: '淘宝检测',
					// 	icon: 'icon-wangluojiance',
					// 	// path: '/pageIndex/sellerTools/checkTaoKe',
					// 	path: 'http://tool.aiodiy.cn/operatingTools/TaobaoMarking',
					//
					// },
				],
			}
		},
		methods: {

			goSellerTools(path){
				window.open(path);
			},
		},
		destroyed() {

		},
		mounted() {

		}
	}
</script>

<style lang="less" scoped>
	.toolsType {
		display: flex;
		flex-wrap: wrap;

		.toolsTypeItem {
			cursor: pointer;
			width: 50%;
			line-height: 55px;
			display: flex;
			align-items: center;

			.iconfont {
				margin-right: 5px;
			}
		}
	}
	.toolsHead {
		display: flex;
		justify-content: space-between;
		margin-bottom: 20px;

		.toolsHeadName {
			font-size: 16px;
		}

		.toolsHeadMore {
			font-size: 12px;
		}
	}
	.sellerTools {
		box-sizing: border-box;
		width: 300px;
		padding: 20px;
		height: 310px;
	}
</style>
