<template>
	<div class="sellerPage ">
		<div class="marqueeBox white">
			<div class="marqueeTitle"><i class="iconfont icon-guangbo guangboColor"></i><span>系统广播：</span></div>
			<div class="marqueeBox1">
				<div class="marquee" ref="marquee" style="margin-left: 1000px;" size="medium">
					<div :id="animate?'marqueeText':'marg'" v-html="notice" class="notice"></div>
				</div>
			</div>
		</div>
		<div class="user_card">
			<!--	用户卡片	-->
			<user-info></user-info>
			<!-- 商家工具 -->
			<seller-tools></seller-tools>
		</div>
		<!-- 跑马灯广告 -->
		<!--		<div class="carousel">-->
		<!--			<el-carousel trigger="click" height="100px">-->
		<!--				<el-carousel-item v-for="(item,index) in carouselList" :key="index">-->
		<!--					<img :src="item.path" class="hoverHand">-->
		<!--				</el-carousel-item>-->
		<!--			</el-carousel>-->
		<!--		</div>-->
		<div class="taskWrap">
			<!-- 今日进度 -->
			<data-progress></data-progress>
			<!--电商课堂-->
			<!--			<classroom></classroom>-->
		</div>
		<!-- 跑马灯广告 -->
		<!--		<div class="carousel">-->
		<!--			<el-carousel trigger="click" height="100px">-->
		<!--				<el-carousel-item v-for="(item,index) in carouselList2" :key="index">-->
		<!--					<img :src="item.path" class="hoverHand">-->
		<!--				</el-carousel-item>-->
		<!--			</el-carousel>-->
		<!--		</div>-->
		<!--  -->
		<!--		<div class="advertWrap white">-->
		<!--			<div class="advertItem" v-for="(item,index) in advertList" :key="index">-->
		<!--				<img :src="item.path" class="hoverHand">-->
		<!--			</div>-->
		<!--		</div>-->
	</div>
</template>

<script>
	import {
		NOTICE,
		USERINFO
	} from '../../api/base'
	import UserInfo from "./components/userInfo";
	import SellerTools from "./components/sellerTools";
	// import Classroom  from "./components/classroom";
	import DataProgress from "./components/dataProgress";
	/*移除HTML标签代码*/
	function removeHTMLTag(str) {
		str = str.replace(/<\/?[^>]*>/g, ''); //去除HTML tag
		str = str.replace(/[ | ]*\n/g, '\n'); //去除行尾空白
		//str = str.replace(/\n[\s| | ]*\r/g,'\n'); //去除多余空行
		str = str.replace(/ /ig, ''); //去掉
		return str;
	}
	export default {
		data() {
			return {
				marqueeText: '',
				animate: true,
				notice: '',
				intervalId: null, // 定时器标识
				advertList: [{
						path: 'http://cdn.dianpujingling.com/other/2020-08-14/ac64b86b-f2c3-4f9d-bd47-09a96eb94901.jpeg',
						link: '',

					},
					{
						path: 'http://cdn.dianpujingling.com/other/2020-08-14/34933650-c1b0-4a52-b925-b4f747cde032.jpeg',
						link: '',

					},
					{
						path: 'http://cdn.dianpujingling.com/other/2020-08-14/89e053e1-88ea-449a-8a39-573f1cff9bba.jpeg',
						link: '',

					},
					{
						path: 'http://cdn.dianpujingling.com/other/2020-08-14/3a11b65d-3d3a-41a6-bd2d-d8b21c9b68ea.jpeg',
						link: '',

					},

				],

				carouselList2: [{
					path: 'http://cdn.dianpujingling.com/other/2020-08-18/005bd0e5-3601-4cd9-a4b7-97d98b6a7680.jpeg'
				}],
				carouselList: [{
					path: 'http://cdn.dianpujingling.com/other/2020-08-21/a07d0a7f-6f6c-42bd-9ec4-c21b759904e3.jpeg'
				}, {
					path: 'http://cdn.dianpujingling.com/other/2020-08-14/822d9d39-844c-4ed5-9ceb-fca5cc99ba8d.jpeg'
				}]
			}
		},
		methods: {

			goRecharge() {
				this.$router.push('/pageIndex/recharge')
			},
			goForgetPassword() {
				// console.log('跳转链接')
				this.$router.push('/pageIndex/forgetPassword')
			},
			goUserinfo() {
				// console.log('跳转链接')
				this.$router.push('/pageIndex/sellerInfo')
			},
			bindShop() {
				// console.log('跳转链接')
				this.$router.push('/pageIndex/shopManagement')
			},
			addGoods() {
				this.$router.push('/pageIndex/goodsManagement')
			},

			handleFetchNotice() {
				this.$axios.get(NOTICE).then(resp => {
					console.log(resp)
					if (resp.code == 200) {
						this.notice = removeHTMLTag(resp.data?.noticeContent||'');
						// this.notice = '亲爱的商家朋友；淘多点礼品代发业务正式上线了，真实礼品，安全高效。具体可以点击左侧快递管理—礼品代发了解操作，或者咨询平台客服！'

					}
				})

			},
			// 定义动画
			scroll() {
				this.animate = true
				const marquee = this.$refs.marquee
				// 	this.animate = false
				setTimeout(() => {
					marquee.style.marginLeft = '0PX'
				}, 500)
				// setTimeout(() => {
				// 	marquee.style.marginLeft = '2500px'
				// 	this.animate = false
				// }, 15000)
			},
			getUserInfo() {
				this.$axios.get(USERINFO).then(res => {
					this.$store.commit('setting/setUserInfo', res.data)
					this.$router.push({
						path: '/pageIndex/sellerCenter'
					})
				}).catch(err => {
					this.$mes({
						message: err.msg,
						type: 'error'
					})

				})
			},
		},
		components: {
			UserInfo,
			SellerTools,
			// Classroom,
			DataProgress,
		},
		destroyed() {
			clearInterval(this.intervalId) // 清除定时器
		},
		mounted() {
			this.scroll() // 动画先执行一次
			this.intervalId = setInterval(this.scroll, 16000) // 间隔时间后重复执行
			this.handleFetchNotice();
			this.getUserInfo()
		}
	}
</script>

<style lang="less" scoped>
	.notice {
		display: flex;

		/deep/ p {
			padding: 0 !important;
			margin: 0 0 !important;
			display: inline-block;

		}
	}

	.advertWrap {
		display: flex;
		justify-content: space-between;
		padding: 20px;

		.advertItem {
			img {
				width: 220px;
				height: 140px;
			}
		}
	}





	.taskWrap {
		display: flex;
		margin-bottom: 20px;
	}

	.activeBut {
		background: linear-gradient(90deg, #ffa55d 0, #f28540);
		color: white;
	}

	.taskInfoTitle {
		margin-bottom: 30px;

		.taskInfoTitleText {
			font-size: 16px;
		}

		.taskInfoTitleTime {
			font-weight: 400;
			font-size: 12px;
		}
	}



	.classroom {
		box-sizing: border-box;
		width: 300px;
		display: inline-block;
		margin-left: 20px;
		height: 274px;
		padding: 20px;
	}

	.carousel {
		margin-bottom: 20px;

		img {
			width: 980px;
			height: 100px;
		}
	}





	.user_card {
		display: flex;
		justify-content: space-around;
		margin-bottom: 20px;




	}

	.anim {
		// transition: all 15s linear;
	}

	.marqueeBox1 {
		white-space: nowrap;
		overflow: hidden;

	}

	.marquee {
		white-space: nowrap;
		overflow: hidden;

		#marqueeText {
			margin-left: 100%; // 把文字弄出可见区域
			width: 100%;
			animation: myMove 30s linear infinite; // 重点，定义动画
			animation-fill-mode: forwards;
		}

		/*文字无缝滚动*/
		@keyframes myMove {
			0% {
				transform: translateX(0);
			}

			100% {
				transform: translateX(-2500px);
			}
		}
	}

	.sellerPage {
		width: 980px;
	}

	.marqueeBox {
		display: flex;
		margin-bottom: 10px;

		.marqueeTitle {
			padding-left: 8px;
			min-width: 100px;
			text-align: center;
		}
	}

	.marqueeBox {
		line-height: 30px;
		width: 100%;

		.guangboColor {
			color: #f24719;
			margin-right: 8px;
		}
	}
</style>
