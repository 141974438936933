<template>
	<div class="user_box ">
		<div class="user_wrap white">
			<div class="fixed">
				<img src="../../../assets/sysImage/sculpture.png">
				<div class="user1">
					<div class="phone">{{userInfo.account}}</div>
<!--							<div class="id">ID：S2012000234</div>-->
					<div class="fast">
						<div class="hoverHand rightLine" @click="bindShop">绑定店铺</div>
						<div class="hoverHand" @click="addGoods">添加宝贝</div>
					</div>
					<div class="fast">
<!--						<div class="hoverHand rightLine" @click="goUserinfo">资料设置</div>-->
						<div class="hoverHand" @click="goForgetPassword">修改密码</div>
					</div>

				</div>
			</div>
			<div class="billBox white">
				<div
					style="width: 320px;display: flex;flex-direction: column;justify-content: center;align-items: center;">
					<div style="width: 190px;">
						<div class="billTitle">账户余额(元)</div>
						<div class="billMoneyBox">
							<span class="billMoney">￥{{userInfo.amount}}</span>
							<span class="billBut">
								<el-button plain size="mini" @click="goRecharge">充值</el-button>
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="taskInfo  white">
			<div class="taskTitle">
				任务状况
			</div>
			<div class="taskInfoNum">
				<div class="taskItem ">
					<div class="taskNum">{{taskInfo.status1}}</div>
					<div class="taskType">待编辑</div>
				</div>
				<div class="taskItem">
					<div class="taskNum">{{taskInfo.status2}}</div>
					<div class="taskType">待审核</div>
				</div>
				<div class="taskItem">
					<div class="taskNum">{{taskInfo.status3}}</div>
					<div class="taskType">运行中</div>
				</div>
				<div class="taskItem">
					<div class="taskNum">{{taskInfo.status4}}</div>
					<div class="taskType">已完成</div>
				</div>
				<div class="taskItem">
					<div class="taskNum">{{taskInfo.status5}}</div>
					<div class="taskType">已撤销</div>
				</div>

			</div>
		</div>
	</div>
</template>

<script>
	import {SITUATION} from '../../../api/base';


	export default {
		data() {
			return {
				userInfo:{},
				taskInfo:{}
			}
		},
		methods: {
			getTaskInfo(){
				this.$axios.get(SITUATION).then(resp=>{
					if(resp.code == 200){
						this.taskInfo = resp.data;
					}
				})
			},
			goRecharge(){
				this.$router.push('/pageIndex/recharge')
			},
			goForgetPassword() {
				// console.log('跳转链接')
				this.$router.push('/pageIndex/forgetPassword')
			},
			goUserinfo() {
				// console.log('跳转链接')
				this.$router.push('/pageIndex/sellerInfo')
			},
			bindShop() {
				// console.log('跳转链接')
				this.$router.push('/pageIndex/shopManagement')
			},
			addGoods() {
				this.$router.push('/pageIndex/goodsManagement')
			},
		},
		destroyed() {
			clearInterval(this.intervalId) // 清除定时器
		},
		mounted() {
			this.userInfo = this.$store.getters['setting/userInfo'];
			this.getTaskInfo()
			// console.log(123);
			// this.$router.push({
			// 	path:'/index/pageIndex'
			// })
		}
	}
</script>

<style lang="less" scoped>
	.taskInfo {
		width: 660px;
		box-sizing: border-box;
		padding: 20px;

		.taskTitle {
			color: #333;
			font-size: 16px;
			font-weight: 700;
			margin-bottom: 37px;
		}

	}
	.billBox {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 320px;

		.billMoney {
			font-size: 20px;
			font-weight: 700;
		}

		.billTitle {
			margin-bottom: 20px;
		}
	}

		.user_box {
			width: 660px;
			margin: 0 20px 20px 0;

			.user_wrap {
				display: flex;
				margin-bottom: 20px;

				.user1 {
					display: inline-block;
					border-right: 1px solid #eee;

					.fast {
						margin-bottom: 4px;

						.rightLine {
							border-right: 1px solid #ddd;
							width: 38%;
							margin-right: 5%;
						}

						div {
							display: inline-block;
							width: 49%;
						}
					}

					.phone {
						width: 180px;
						height: 30px;
						font-weight: 700;
						font-size: 16px;
						color: #333;
						line-height: 32px;
					}

				}

				img {
					width: 80px;
					height: 80px;
					margin: 30px;
				}
			}
		}
		.fixed {
			display: flex;
			align-items: center;

		}
	.taskInfo {
		width: 660px;
		box-sizing: border-box;
		padding: 20px;

		.taskTitle {
			color: #333;
			font-size: 16px;
			font-weight: 700;
			margin-bottom: 37px;
		}

	}
	.taskInfoNum {
		display: flex;
		justify-content: space-around;
		text-align: center;

		.taskItem {
			cursor: pointer;
		}

		.taskNum {
			color: #ff714b;
			font-size: 24px;
			font-weight: 700;
			margin-bottom: 5px;
		}

		.taskType {}
	}
	.billMoneyBox {
		display: flex;
		align-items: center;
	}
	.billBut {
		.is-plain {
			margin-left: 16px;
		}

		.is-plain:hover {
			background: #fff;
			border-color: @primaryColorQian;
			color: @primaryColorQian;
		}
	}
</style>
