<template>
    <div class="taskInfo1 white">
        <div class="taskInfoTitle">
            <span class="taskInfoTitleText">今日进度</span>
            <span class="taskInfoTitleTime">（{{date}}）</span>
        </div>
<!--        <div class="tap">-->
<!--            <div class="taskType1 hoverHand" :class="activeTaskType==0?'activeBut':''" @click="changeTask(0)">-->
<!--                垫付任务</div>-->
<!--            <div class="taskType2 hoverHand" :class="activeTaskType==1?'activeBut':''" @click="changeTask(1)">-->
<!--                人气任务</div>-->
<!--        </div>-->
<!--        <div style="text-align: center;height: 20px;">-->
<!--            <span v-show="activeTaskType == 0">包含高权重任务</span>-->
<!--        </div>-->
        <div class="taskTypeWrap">
            <div class="taskTypeItem hoverHand">
                <div class="taskTypeNum">{{dataProgress.publish}}</div>
                <div class="taskTypeText">发布总数</div>
            </div>
            <div class="taskTypeItem hoverHand">
                <div class="taskTypeNum">{{dataProgress.orders}}</div>
                <div class="taskTypeText">接单总数</div>
            </div>
<!--            <div class="taskTypeItem hoverHand">-->
<!--                <div class="taskTypeNum">0</div>-->
<!--                <div class="taskTypeText">佣金总额</div>-->
<!--            </div>-->
<!--            <div class="taskTypeItem hoverHand">-->
<!--                <div class="taskTypeNum">0</div>-->
<!--                <div class="taskTypeText">耗费佣金</div>-->
<!--            </div>-->
        </div>
    </div>
</template>

<script>

    import {PROGRESS} from "../../../api/base";

    export default {
        name: "dataProgress",
        data() {
            return {
                activeTaskType:0,
                date:'',
                dataProgress:{}
            }
        },
        methods: {
            changeTask(index) {
                this.activeTaskType = index;
            },
            getdate(){
                this.$axios.get(PROGRESS).then(resp=>{
                    if(resp.code == 200){
                        this.dataProgress = resp.data;
                    }
                })
            }
        },
        components: {},
        mounted() {
            this.getdate();
            //获取当前时间
            const date = new Date();
            const Y = date.getFullYear();
            const M = date.getMonth()+1<10?'0'+(date.getMonth()+1):date.getMonth()+1;
            const D = date.getDate()<10?'0'+date.getDate():date.getDate();
            this.date = Y+'/'+M+'/'+D
        }
    }
</script>

<style scoped lang="less">
    .taskTypeWrap {
        padding: 20px 0;
        display: flex;
        justify-content: space-around;
        text-align: center;
        .taskTypeNum {
            color: #ff714b;
            font-weight: 700;
            margin-bottom: 20px;
            font-size: 24px
        }

        .taskTypeText {
            font-size: 14px;
        }
    }

    .taskInfoTitle {
        margin-bottom: 30px;

        .taskInfoTitleText {
            font-size: 16px;
            font-weight: 700;

        }

        .taskInfoTitleTime {
            font-weight: 400;
            font-size: 12px;
        }
    }

    .taskInfo1 {
        width: 660px;
        display: inline-block;
        height: 274px;
        box-sizing: border-box;
        padding: 20px;

        .tap {
            margin-bottom: 20px;
            text-align: center;
            line-height: 30px;

            .taskType1 {
                border: 1px solid #eee;
                border-right: 0px;
                display: inline-block;
                height: 30px;
                width: 120px;
                border-radius: 15px 0 0 15px;
            }

            .taskType2 {
                border: 1px solid #eee;
                border-left: 0px;
                display: inline-block;
                height: 30px;
                width: 120px;
                border-radius: 0 15px 15px 0;
            }
        }
    }
</style>